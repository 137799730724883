import react, { useState, useEffect } from "react";
import styles from "./Banner.module.css";

const Banner = () => {
  return (
    <div id="home" className={styles.banner}>
      <div className="">
        <img
          className={styles.logoBanner}
          src="https://cognitiveblue.humanelevation.com/assets/images/cognitive-blue-logo.png"
          alt=""
          width="100"
        />
        <div className={styles.bannercontent}>
          <h1>Early Registration </h1>
          <p>
            Cognitive Blue digital e-course and seminar. Registration opens up
            August 15, 2021
          </p>
          <a
            href="#contact"
            className={`btn tbn-primary ${styles.button}`}
            style={{ marginRight: "10px" }}
          >
            Pre-registration
          </a>
          <a
            href="https://cognitiveblue.humanelevation.com/assets/images/cognitive.pdf"
            className={`btn tbn-primary ${styles.button}`}
            download
          >
            Download Whitepaper
          </a>
        </div>
      </div>
    </div>
  );
};

export default Banner;

import react, { useState, useEffect } from "react";
import styles from "./Nav.module.css";

const Nav = () => {
  return (
    <nav
      className={`navbar navbar-expand-md navbar-light ${styles.navbarCustom}`}
    >
      <a href="#home">
        <img
          src="https://cognitiveblue.humanelevation.com/favicon.png"
          width="50"
          alt=""
        />
      </a>{" "}
      <span className={styles.brand}>COGNITIVE BLUE</span>
      <button
        className="navbar-toggler "
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav mr-auto" />
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" href="#sylabus">
              Syllabus
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#services">
              Services
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#contact">
              Contact Us
            </a>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link" href="#0">
              <img
                className={styles.userIcon}
                src="https://cognitiveblue.humanelevation.com/assets/images/user-icon.svg"
                alt=""
              />
              Login
            </a>
          </li> */}
        </ul>
      </div>
    </nav>
  );
};

export default Nav;

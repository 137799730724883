import react, { useState, useEffect } from "react";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer id="contact" className={styles.footer}>
      <a href="#home">
        <img
          className={styles.logoBanner}
          src="https://cognitiveblue.humanelevation.com/assets/images/cognitive-blue-logo-black.png"
          alt=""
          width="150"
        />
      </a>
      <p>
        Transformational business advice starts with listening to you and
        helping you uncover the issues you face and the opportunities available
        to you.
      </p>
      <p>
        <strong>For Pre-registration or business services</strong>{" "}
        <a href="mailto:hello@humanelevation.com">Contact us</a>
      </p>
    </footer>
  );
};

export default Footer;

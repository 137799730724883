import styles from "./home.module.css";
import Banner from "../../components/Banner";
import Nav from "../../components/Nav";
import Sylabus from "../../components/Sylabus";
import BusinessConsulting from "../../components/BusinessConsulting";
import Footer from "../../components/Footer";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const [red, green, blue] = [242, 100, 51];
    const section1 = document.querySelector(".navbar");

    window.addEventListener("scroll", () => {
      let y = 1 + (window.scrollY || window.pageYOffset) / 500;
      y = y < 1 ? 1 : y; // ensure y is always >= 1 (due to Safari's elastic scroll)
      const [r, g, b, a] = [red / y, green / y, blue / y, y > 1 ? 1 : 0].map(
        Math.round
      );
      if (section1) {
        section1.style.backgroundColor = `rgb(${r}, ${g}, ${b}, ${a})`;
      }
    });
  }, []);
  return (
    <div className={styles.body}>
      <Nav />
      <Banner />
      <Sylabus />
      <BusinessConsulting />
      <Footer />
    </div>
  );
}

export default App;

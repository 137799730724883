import react, { useState, useEffect } from "react";
import styles from "./Sylabus.module.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const carousel = [
  {
    image:
      "https://cognitiveblue.humanelevation.com/assets/images/sylabus/introduction.jpg",
    title: "Introduction to Consumer Psychology",
  },
  {
    image:
      "https://cognitiveblue.humanelevation.com/assets/images/sylabus/impact.jpg",
    title: "Impact of Technology on Human Behavior",
  },
  {
    image: "http://localhost:3000/assets/images/sylabus/tech.jpeg",
    title: "How to Design Technology to produce Cognitive Human Behavior",
  },
  {
    image:
      "https://cognitiveblue.humanelevation.com/assets/images/sylabus/media.jpg",
    title: "How Media Plays a Role in Human Cognition",
  },
  {
    image:
      "https://cognitiveblue.humanelevation.com/assets/images/sylabus/awareness.jpg",
    title: "The Impact of Cognitive Awareness on Your Health and Community",
  },
  {
    image:
      "https://cognitiveblue.humanelevation.com/assets/images/sylabus/consummerism.jpg",
    title: "The Culture of Consumerism and Business of Capitalism",
  },
];
const Sylabus = () => {
  return (
    <div id="sylabus" className={styles.sylabusComponent}>
      <h1>Syllabus</h1>
      <p>
        The course consist of Print Books, Whitepapers, audio and video series
        to guide you through the course.{" "}
      </p>
      <OwlCarousel
        className="owl-theme"
        loop
        margin={0}
        nav
        dots={false}
        draggable
      >
        {carousel.map(({ image, title }) => (
          <div className="item" key={title}>
            <img src={image} alt={title} />
            <p className={styles.carouselItemTitle}>{title}</p>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default Sylabus;

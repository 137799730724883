import react, { useState, useEffect } from "react";
import styles from "./BusinessConsulting.module.css";
const services = [
  {
    image:
      "https://cognitiveblue.humanelevation.com/assets/images/strategy-icon.svg",
    title: `The company's practice includes assisting clients with developments 
    such as developing a new products or services  for their organization (strategy), 
    creating an advertising plan that is both targeted and cost-effective (marketing) 
    or putting together pricing plans and infrastructure for distribution channels 
    based on the client’s goals (sales).`,
  },
  {
    image:
      "https://cognitiveblue.humanelevation.com/assets/images/research-icon.svg",
    title: `We provide one-stop shop for all things marketing: from market research 
    to creative design services like branding or brochures; digital media, TV or 
    radio campaigns that will help you take control of how your message gets heard 
    by speaking directly with customers at their point of purchase and engagement.`,
  },
  {
    image:
      "https://cognitiveblue.humanelevation.com/assets/images/outsource-icon.svg",
    title: `We are committed to providing you with the best possible consulting 
      services and outsource solutions that will allow your company thrive in 
      this volatile economic climate. That's why we offer Supply Chain Management 
      Services as well as Human Capital Management Solutions so businesses can 
      adapt more easily within these shifting markets`,
  },
  {
    image:
      "https://cognitiveblue.humanelevation.com/assets/images/management-icon.svg",
    title: `We provide infrastructure and software consultation services to help 
    you navigate your way through the digital era. This includes everything from 
    providing framework and quantitive research, design or industry analysis for 
    all projects that are related in any capacity with our value engineered 
    business solutions.`,
  },
];
const BusinessConsulting = () => {
  return (
    <div id="services" className={styles.businessConsultingSection}>
      <div>
        <div className={`${styles.content}`}>
          <div className={styles.contentWrapper}>
            <h1>Business Consulting</h1>
            <h5>Focused on helping your business thrive. </h5>
            <p>
              Unlike some providers who provide “boxed” solutions based on their
              service offerings, we identify your value propositions and,
              working with your leadership team, develop and help you execute
              strategies to engineer a valued solution.
            </p>
          </div>
          <div className={`${styles.consultingThumbs}`}>
            {services.map(({ image, title }) => (
              <div className={styles.item} key={title.replace(" ", "-")}>
                <img src={image} alt={title.replace(" ", "-")} />
                <p>{title.replace(" ", "-")}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessConsulting;
